window.vidHandler = () => {
  if(window.videoLoaded) {
    return;
  }
  window.videoLoaded = {};
  document.addEventListener('DOMContentLoaded', () => {
    videoEvents();
    togglePlayVideo();
  });

  const toggleMuteVideo = (video, muteBtn) => {
    const isMute = muteBtn.getAttribute('data-mute');

    video.muted = isMute === 'true';
  };

  const togglePlayVideo = () => {
    const videos = document.querySelectorAll('.jsVid');

    videos.forEach((video, index) => {
      const playButton = video.parentNode.querySelector('.jsVidPlay');
      const muteBtn = video.parentNode.querySelector('.jsVidMute');

      video.addEventListener('click', () => {
        playButton.click();
        !window[`firstAdditUnmute_${index}`]
        && (playButton.getAttribute('data-play') === 'true')
        && (muteBtn.getAttribute('data-mute') === 'true')
        && muteBtn.click();
      });
    });
  };

  const setProgress = (videoContainer, offset, left) => {
    const video = videoContainer.querySelector('.jsVid');
    const progressBar = videoContainer.querySelector('.querySelector');
    const playBtn = videoContainer.querySelector('.jsVidPlay');
    const isVideoPlay = playBtn.getAttribute('data-play');
    const totalWidth = progressBar.offsetWidth;
    const percentage = (left / totalWidth);
    const videoTime = video.duration * percentage;

    video.currentTime = offset === null ? window.videoProgress : videoTime;

    offset !== null && isVideoPlay === 'true' && video.play(); // Continue video playing after change progress
  };

  const videoToolEvents = (videoContainer, videoIdx) => {
    const video = videoContainer.querySelector('.jsVid');
    const progressBar = videoContainer.querySelector('.jsVidProgress');
    const playBtn = videoContainer.querySelector('.jsVidPlay');
    const muteBtn = videoContainer.querySelector('.jsVidMute');
    const volumeBar = videoContainer.querySelector('.jsVidVolumeBar');
    const progress = progressBar.querySelector('span');
    const fullScreenBtn = videoContainer.querySelector('.jsVidFS');
    const videoControls = videoContainer.querySelector('.jsVidControls');


    // Set volume values after page is loaded
    if (!window.videoLoaded[videoIdx]) {
      video.volume = 0;
      volumeBar.value = 0;
      window.videoLoaded[videoIdx] = 1;
    }

    video.ontimeupdate = function () {
      const {currentTime, duration} = video;
      const progressValue = (currentTime / duration);

      // Set global progress bar value
      window.videoProgress = video.duration * progressValue;

      // Progress bar
      progress.style.width = `${progressValue * 100}%`;

      // When video is finished, change icons from pasue to play. 1 means 100%
      progressValue === 1 && playBtn.setAttribute('data-play', 'false');
      playBtn.getAttribute('data-play') === 'false' ? videoControls.classList.add('visible') : videoControls.classList.remove('visible');
    };

    playBtn.addEventListener('click', () => {
      const isPlaying = playBtn.getAttribute('data-play');

      toggleMuteVideo(video, muteBtn);

      if (isPlaying === 'true') {
        playBtn.setAttribute('data-play', 'false');
        video.pause();
      } else {
        playBtn.setAttribute('data-play', 'true');
        video.play();
      }
    });

    muteBtn.addEventListener('click', () => {
      const isMute = muteBtn.getAttribute('data-mute');

      if (isMute === 'true') {
        // Mute
        muteBtn.setAttribute('data-mute', 'false');
        (window[`firstAdditUnmute_${videoIdx}`] = true) // Set max volume after first unmute
        && (video.volume = 1);
        volumeBar.value = video.volume * 100;
      } else {
        // Unmute
        muteBtn.setAttribute('data-mute', 'true');
        volumeBar.value = 0;
      }

      toggleMuteVideo(video, muteBtn);
    });

    volumeBar.addEventListener('input', (e) => {
      const val = e.target.value;

      window[`firstAdditUnmute_${videoIdx}`] = true; // Cancel possibility to set max volume before first unmute video
      video.volume = val / 100;
      video.muted = val === '0';
      muteBtn.setAttribute('data-mute', `${val === '0' ? 'true' : 'false'}`);
    });

    progressBar.addEventListener('click', function (e) {
      const offset = $(this).offset();
      const left = (e.pageX - offset.left);

      setProgress(videoContainer, offset, left);
    });

    fullScreenBtn.addEventListener('click', () => {
      if (video.requestFullscreen) {
        video.requestFullscreen();
      } else if (video.webkitRequestFullscreen) { /* Safari */
        video.webkitRequestFullscreen();
      } else if (video.msRequestFullscreen) { /* IE11 */
        video.msRequestFullscreen();
      } else if (video.mozRequestFullScreen) { /* FF */
        video.mozRequestFullScreen();
      }
    });
  }

  const videoEvents = () => {
    const videoContainerPage = document.querySelectorAll('.jsVidContainer');
    videoContainerPage.forEach((container, index) => videoToolEvents(container, index));
  };
}
